import React, { useContext, useState, useEffect } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BoxArrowRight, ClipboardCheck, Grid, People, Basket3, List, Person } from 'react-bootstrap-icons';
import logo from '../img/logo-gallito-1.png';
import { AuthContext } from '../authContext';
import Swal from 'sweetalert2';

const Sidebar = () => {
  const { user, logout } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Estado para controlar el menú lateral en móvil

  const handleLogout = () => {
    logout();
    Swal.fire({
      icon: 'info',
      title: 'Sesión cerrada',
      text: 'Has cerrado sesión exitosamente',
    }).then(() => {
      window.location.href = '/login';
    });
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen); // Alterna el estado del menú

  const menuItems = [
    { path: '/checkin', icon: <ClipboardCheck className="sidebar-icon" />, label: 'Check-In', show: user && (user.rol === 'admin' || user.rol === 'recepcion') },
    { path: '/admin', icon: <People className="sidebar-icon" />, label: 'Comandero', show: user && (user.rol === 'admin' || user.rol === 'comandero') },
    { path: '/mesas', icon: <Grid className="sidebar-icon" />, label: 'Mesas', show: user && user.rol === 'admin' },
    { path: '/comidas', icon: <Basket3 className="sidebar-icon" />, label: 'Comidas', show: user && user.rol === 'admin' },
    { path: '/categorias', icon: <List className="sidebar-icon" />, label: 'Categorías', show: user && user.rol === 'admin' },
    { path: '/usuarios', icon: <Person className="sidebar-icon" />, label: 'Usuarios', show: user && user.rol === 'admin' },
    { path: '/logout', icon: <BoxArrowRight className="sidebar-icon" />, label: 'Logout', action: handleLogout, show: true },
  ];

  useEffect(() => {
    const accessibleItems = menuItems.filter(item => item.show);
    console.log('Elementos de menú accesibles para el usuario:', accessibleItems);
  }, [user]);

  return (
    <>
      {/* Botón para abrir/cerrar el menú en dispositivos móviles */}
      <button className="menu-toggle-btn" onClick={toggleSidebar}>
        ☰
      </button>

      <Nav className={`sidebar ${isSidebarOpen ? 'open' : ''} d-flex flex-column align-items-center bg-dark text-white vh-100 p-3`}>
        <div className="text-center mb-4">
          <center><img src={logo} alt="El Gallito Mañanero" style={{ width: '60px', marginBottom: '10px' }} /></center>
          <div className="text-white text-center rounded" style={{ fontSize: '1.2em', padding: '5px 10px', marginTop: '10px' }}>
            {user?.sucursal?.nombre || 'Sucursal no disponible'}
          </div>
        </div>
        {menuItems
          .filter(item => item.show)
          .map((item, index) => (
            <OverlayTrigger
              key={index}
              placement="right"
              overlay={<Tooltip id={`tooltip-${index}`}>{item.label}</Tooltip>}
            >
              <Nav.Link
                as={Link}
                to={item.path}
                className="text-white py-3 d-flex align-items-center justify-content-center"
                onClick={item.action ? item.action : null}
              >
                {item.icon}
              </Nav.Link>
            </OverlayTrigger>
          ))}
      </Nav>
    </>
  );
};

export default Sidebar;
