import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('userName');
    const sucursalString = localStorage.getItem('sucursal');
    const userRole = localStorage.getItem('userRole'); // Recuperar el rol del usuario

    let sucursal = null;

    try {
      sucursal = sucursalString ? JSON.parse(sucursalString) : null;
    } catch (error) {
      console.error('Error parsing sucursal from localStorage:', error);
    }

    if (token && userId && userName && sucursal && userRole) {
      setIsAuthenticated(true);
      setUser({ 
        id: parseInt(userId, 10), 
        nombre_usuario: userName, 
        sucursal, 
        rol: userRole // Añadir el rol al estado del usuario
      });
    } else {
      console.error('Error: Datos de autenticación faltantes o inválidos');
    }
  }, []);

  const login = (token, userId, userName, sucursal, userRole) => {
    if (!sucursal || !sucursal.id) {
      console.error('Sucursal inválida:', sucursal);
      return;
    }

    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId);
    localStorage.setItem('userName', userName);
    localStorage.setItem('sucursal', JSON.stringify(sucursal));
    localStorage.setItem('userRole', userRole);
    setIsAuthenticated(true);
    setUser({ id: userId, nombre_usuario: userName, sucursal, rol: userRole });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('sucursal');
    localStorage.removeItem('userRole'); // Eliminar el rol del localStorage
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
