import React, { useState, useEffect, useContext } from "react";
import API from "../axiosConfig";
import { AuthContext } from "../authContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Modal, Button, Nav } from "react-bootstrap";
import Swal from "sweetalert2";
import "./AdminPage.css";
import "@fontsource/roboto-mono";

const AdminPage = () => {
  const { user } = useContext(AuthContext);
  const [visitas, setVisitas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [platillos, setPlatillos] = useState([]);
  const [filteredPlatillos, setFilteredPlatillos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedPlatillos, setSelectedPlatillos] = useState([]);
  const [selectedVisita, setSelectedVisita] = useState(null);
  useEffect(() => {
    const fetchVisitas = async () => {
      const sucursalId = user?.sucursal?.id;
  
      if (sucursalId) {
        try {
          const response = await API.get(`/visitas?sucursal_id=${sucursalId}`);
          console.log("Datos recibidos de la API:", response.data);
  
          const visitasConPlatillos = response.data.map((visita) => {
            const visitaExistente = visitas.find((v) => v.id === visita.id);
  
            // Si la visita ya existe, mantenemos sus valores de tiempo
            if (visitaExistente) {
              return {
                ...visitaExistente, // Conserva timeWaiting y formattedTimeWaiting actuales
                ...visita, // Actualiza otros datos de la visita
              };
            } else {
              // Si es una nueva visita, establece timeWaiting inicial
              const createdAt = new Date(visita.createdAt.replace(" ", "T"));
              const now = new Date();
              const totalSecondsWaiting = Math.floor((now - createdAt) / 1000);
  
              return {
                ...visita,
                platillos: visita.platillo_mas_pedido
                  ? [
                      {
                        id: visita.platillo_id,
                        nombre: visita.platillo_mas_pedido,
                        categoria_nombre: "Más pedido",
                      },
                    ]
                  : [],
                selectedPlatillos: visita.platillo_mas_pedido
                  ? [
                      {
                        id: visita.platillo_id,
                        nombre: visita.platillo_mas_pedido,
                        categoria_nombre: "Más pedido",
                      },
                    ]
                  : [],
                ordenTomada: false,
                timeWaiting: totalSecondsWaiting,
                formattedTimeWaiting: `${Math.floor(totalSecondsWaiting / 3600)} hrs ${Math.floor(
                  (totalSecondsWaiting % 3600) / 60
                )} mins`,
              };
            }
          });
  
          setVisitas(visitasConPlatillos);
        } catch (error) {
          console.error("Error fetching visitas:", error);
          setVisitas([]);
        }
      } else {
        console.error("Sucursal ID no encontrado.");
      }
    };
  
    fetchVisitas();
    const interval = setInterval(fetchVisitas, 3000);
  
    return () => clearInterval(interval);
  }, [user?.sucursal?.id]);
  
  // Calcula el tiempo de espera basado en createdAt
  useEffect(() => {
    const updateWaitingTimes = () => {
      setVisitas((prevVisitas) =>
        prevVisitas.map((visita) => {
          if (visita.ordenTomada) return visita; // No actualizar si ya se tomó la orden
  
          const updatedTimeWaiting = visita.timeWaiting + 1; // Incrementa el tiempo en segundos
          const hours = Math.floor(updatedTimeWaiting / 3600);
          const minutes = Math.floor((updatedTimeWaiting % 3600) / 60);
          const formattedTimeWaiting = `${hours > 0 ? hours + " hrs " : ""}${minutes} mins`;
  
          return {
            ...visita,
            timeWaiting: updatedTimeWaiting,
            formattedTimeWaiting,
          };
        })
      );
    };
  
    // Llama a updateWaitingTimes inmediatamente y luego cada segundo
    updateWaitingTimes();
    const interval = setInterval(updateWaitingTimes, 1000);
  
    return () => clearInterval(interval); // Limpiar intervalo al desmontar
  }, []);
  

  useEffect(() => {
    const fetchPlatillos = async () => {
      try {
        const response = await API.get("/platillos");
        setPlatillos(response.data);
        setFilteredPlatillos(response.data);

        const uniqueCategories = [
          ...new Set(response.data.map((item) => item.categoria_nombre)),
        ];
        setCategories(uniqueCategories);
        setActiveCategory(uniqueCategories[0]);
      } catch (error) {
        console.error("Error fetching platillos:", error);
      }
    };

    fetchPlatillos();
  }, []);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setFilteredPlatillos(
      category
        ? platillos.filter((item) => item.categoria_nombre === category)
        : platillos
    );
  };

  const handleShowModal = (visita) => {
    setSelectedVisita(visita);
    setSelectedPlatillos(visita.selectedPlatillos || []);

    // Actualizar la propiedad `ordenTomada` a true para detener el cronómetro
    setVisitas((prevVisitas) =>
      prevVisitas.map((v) =>
        v.id === visita.id ? { ...v, ordenTomada: true } : v
      )
    );

    setShowModal(true);
  };

  const handleCloseModal = async () => {
    if (selectedVisita) {
      const platilloIds = selectedPlatillos.map((p) => p.id).join(",");
      if (!selectedVisita.id) {
        Swal.fire(
          "Error",
          "El ID de la visita y los platillos son obligatorios.",
          "error"
        );
        return;
      }

      try {
        await API.put(
          `/visitas?id=${selectedVisita.id}&action=updatePlatillos`,
          { platillo_id: platilloIds }
        );
        setVisitas((prevVisitas) =>
          prevVisitas.map((v) =>
            v.id === selectedVisita.id
              ? { ...v, selectedPlatillos: selectedPlatillos, timeWaiting: 0 } // Reiniciar el tiempo de espera al añadir
              : v
          )
        );
      } catch (error) {
        Swal.fire(
          "Error",
          "Hubo un problema al actualizar la visita.",
          "error"
        );
      }
    }
    setShowModal(false);
  };

  const handlePlatilloSelect = (platillo) => {
    setSelectedPlatillos((prevSelected) => {
      if (prevSelected.some((p) => p.id === platillo.id)) {
        return prevSelected.filter((p) => p.id !== platillo.id);
      } else {
        return [...prevSelected, platillo];
      }
    });
  };

  const handleLiberarMesa = async (visita) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Quieres liberar esta mesa? Esta acción no se puede deshacer.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, liberar",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        await API.put(`/visitas?id=${visita.id}&action=updateEstado`, {
          estado: "completada",
        });

        // Envía todos los IDs de las mesas en un solo array
        const mesaIds = visita.numero_mesa.split(", "); // Divide los IDs si están en formato de cadena
        await API.put(`/mesas?action=updateEstadoMultiple`, {
          mesa_ids: mesaIds,
          esta_ocupada: false,
        });

        setVisitas(visitas.filter((v) => v.id !== visita.id));
        Swal.fire("Liberada!", "Las mesas han sido liberadas.", "success");
      } catch (error) {
        Swal.fire("Error", "Hubo un problema al liberar las mesas.", "error");
      }
    }
  };

  const isBirthdayInLastWeek = (fechaCumpleanos) => {
    const today = new Date();
    const birthdayThisYear = new Date(
      today.getFullYear(),
      fechaCumpleanos.getMonth(),
      fechaCumpleanos.getDate()
    );

    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);

    return birthdayThisYear >= oneWeekAgo && birthdayThisYear <= today;
  };

  return (
    <div
      className="container mt-4"
      style={{ fontFamily: "'Roboto Mono', monospace" }}
    >
      <div className="row">
        {visitas.length > 0 ? (
          visitas.map((visita) => (
            <div
              className="col-md-4 col-sm-6 mb-4"
              key={visita.cliente_nombre + visita.numero_mesa}
            >
              <div
                className="card h-100 shadow-sm rounded border-0 position-relative"
                style={{
                  backgroundColor: "#ffffff",
                  padding: "10px",
                  borderRadius: "15px",
                }}
              >
                {/* Cronómetro en la esquina superior derecha */}
                <div
                  className="position-absolute top-0 end-0 bg-warning text-dark px-2 py-1 rounded-start"
                  style={{ fontSize: "0.8em", fontWeight: "bold" }}
                >
                  {visita.ordenTomada
                    ? "Orden tomada"
                    : `${visita.formattedTimeWaiting} en espera`}
                </div>

                <div className="card-header bg-light d-flex justify-content-between align-items-center rounded-top">
                  <span
                    className="fw-bold text-dark"
                    style={{ fontSize: "1.2em", fontWeight: "bold" }}
                  >
                    {visita.cliente_nombre}
                  </span>
                  <span
                    className="badge bg-primary rounded-pill"
                    style={{ fontSize: "0.9em", fontWeight: "bold" }}
                  >
                    Mesa: {visita.nombre_mesa}
                  </span>
                </div>

                <div
                  className="card-body d-flex flex-column"
                  style={{ padding: "15px" }}
                >
                  {(visita.es_cumpleanero ||
                    isBirthdayInLastWeek(
                      new Date(visita.fecha_cumpleanos)
                    )) && (
                    <div className="birthday-banner">
                      <span
                        role="img"
                        aria-label="birthday"
                        className="birthday-icon"
                      >
                        🎉
                      </span>
                      <strong>
                        {visita.es_cumpleanero && "¡Feliz cumpleaños!"}
                        {isBirthdayInLastWeek(
                          new Date(visita.fecha_cumpleanos)
                        ) && "¡Cumpleaños esta semana!"}
                      </strong>
                      <br />
                    </div>
                  )}

                  <h5
                    className="card-title text-info"
                    style={{ fontWeight: "bold" }}
                  >
                    Lo más pedido
                  </h5>

                  <ul
                    className="list-unstyled flex-grow-1"
                    style={{ marginBottom: "15px" }}
                  >
                    {visita.platillos && visita.platillos.length > 0 ? (
                      visita.platillos.map((platillo) => (
                        <li key={platillo.id} style={{ marginBottom: "5px" }}>
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#f0f0f0",
                              color: "#333",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              fontSize: "0.9em",
                              fontWeight: "bold",
                            }}
                          >
                            {platillo.nombre}
                          </span>
                          <small
                            className="text-secondary"
                            style={{ marginLeft: "5px", fontSize: "0.85em" }}
                          >
                            ({platillo.categoria_nombre})
                          </small>
                        </li>
                      ))
                    ) : (
                      <li className="text-muted">No hay platillos pedidos</li>
                    )}
                  </ul>
                  <h5
                    className="card-title text-info mt-3"
                    style={{ fontWeight: "bold" }}
                  >
                    Pedido
                  </h5>
                  <ul
                    className="list-unstyled flex-grow-1"
                    style={{ marginBottom: "15px" }}
                  >
                    {visita.selectedPlatillos &&
                    visita.selectedPlatillos.length > 0 ? (
                      visita.selectedPlatillos.map((platillo) => (
                        <li key={platillo.id} style={{ marginBottom: "5px" }}>
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "#f0f0f0",
                              color: "#333",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              fontSize: "0.9em",
                              fontWeight: "bold",
                            }}
                          >
                            {platillo.nombre}
                          </span>
                        </li>
                      ))
                    ) : (
                      <li className="text-muted">
                        No hay platillos en el pedido
                      </li>
                    )}
                  </ul>
                  {new Date(visita.ultima_visita).toDateString() ===
                    new Date().toDateString() && (
                    <div
                      className="alert alert-info mt-3 p-2"
                      role="alert"
                      style={{ fontSize: "0.85em", backgroundColor: "#eaf7ff" }}
                    >
                      Última visita:{" "}
                      {new Date(visita.ultima_visita).toLocaleDateString()}
                    </div>
                  )}
                </div>
                <div
                  className="card-footer d-flex justify-content-between bg-light border-top-0"
                  style={{ padding: "10px 15px" }}
                >
                  <Button
                    variant="primary"
                    onClick={() => handleShowModal(visita)}
                    style={{
                      width: "48%",
                      fontSize: "1em",
                      fontWeight: "bold",
                    }}
                  >
                    <i className="bi bi-plus-circle"></i> Añadir
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleLiberarMesa(visita)}
                    style={{
                      width: "48%",
                      fontSize: "1em",
                      fontWeight: "bold",
                    }}
                  >
                    <i className="bi bi-x-circle"></i> Liberar
                  </Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p className="text-muted">No hay visitas para mostrar.</p>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
  <Modal.Header closeButton>
    <Modal.Title style={{ fontFamily: "'Roboto Mono', monospace" }}>
      Seleccionar Platillos
    </Modal.Title>
  </Modal.Header>
  <Modal.Body className="d-flex flex-column">
    <div className="d-flex">
      <Nav
        variant="pills"
        className="flex-column nav-tabs-custom"
        activeKey={activeCategory}
        onSelect={handleCategoryChange}
      >
        {categories.map((category, index) => (
          <Nav.Item key={index}>
            <Nav.Link
              eventKey={category}
              className="text-center"
              style={{ fontFamily: "'Roboto Mono', monospace" }}
            >
              {category}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <div className="flex-grow-1 ms-3">
        <div className="platillos-grid">
          {filteredPlatillos.map((platillo) => (
            <div
              key={platillo.id}
              className="platillo-item list-group-item"
              onClick={() => handlePlatilloSelect(platillo)}
              style={{
                fontFamily: "'Roboto Mono', monospace",
                cursor: "pointer",
                backgroundColor: selectedPlatillos.some((p) => p.id === platillo.id)
                  ? "#d1e7dd"
                  : "#f8f9fa",
                color: selectedPlatillos.some((p) => p.id === platillo.id)
                  ? "#155724"
                  : "#333",
                fontWeight: selectedPlatillos.some((p) => p.id === platillo.id)
                  ? "bold"
                  : "normal",
              }}
            >
              {platillo.nombre}{" "}
              <span className="text-muted">({platillo.categoria_nombre})</span>
            </div>
          ))}
        </div>
      </div>
    </div>
    
    {/* Listado de platillos seleccionados en la parte inferior */}
    <div className="selected-platillos-list mt-4">
      <h5>Platillos Seleccionados:</h5>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        {selectedPlatillos.map((platillo) => (
          <li key={platillo.id} className="selected-platillo-item">
            <span style={{ fontWeight: "bold" }}>{platillo.nombre}</span> -{" "}
            <span className="text-muted">{platillo.categoria_nombre}</span>
          </li>
        ))}
      </ul>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button
      variant="secondary"
      onClick={handleCloseModal}
      style={{ fontFamily: "'Roboto Mono', monospace" }}
    >
      Cerrar
    </Button>
  </Modal.Footer>
</Modal>

    </div>
  );
};

export default AdminPage;
