import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import API from '../axiosConfig';
import Swal from 'sweetalert2';
import { Button, Modal, Form } from 'react-bootstrap';

const MesasPage = () => {
  const [sucursales, setSucursales] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingMesa, setEditingMesa] = useState(null);
  const [formData, setFormData] = useState({
    sucursal_id: '',
    numero_mesa: '',
  });

  const tableRef = useRef(null);
  const dataTableInstance = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetchSucursales();
      await fetchMesas();
    };
    fetchData();
  }, []);

  const fetchMesas = async () => {
    try {
      const response = await API.get('/mesas');
      if (dataTableInstance.current) {
        dataTableInstance.current.clear().rows.add(response.data).draw();
      } else {
        initializeDataTable(response.data);
      }
    } catch (error) {
      console.error('Error fetching mesas:', error);
    }
  };

  const fetchSucursales = async () => {
    try {
      const response = await API.get('/sucursales');
      setSucursales(response.data);
    } catch (error) {
      console.error('Error fetching sucursales:', error);
    }
  };

  const initializeDataTable = (data) => {
    const tableElement = tableRef.current;
  
    if (!$.fn.dataTable.isDataTable(tableElement)) {
      dataTableInstance.current = $(tableElement).DataTable({
        data: data,
        columns: [
          { data: 'id', title: 'ID' },
          { data: 'sucursal_nombre', title: 'Sucursal' },
          { data: 'numero_mesa', title: 'Número de Mesa' },
          {
            data: null,
            title: 'Acciones',
            render: function (data, type, row) {
              return `
                <button class="btn btn-warning btn-sm edit-btn"><i class="bi bi-pencil"></i></button>
                <button class="btn btn-danger btn-sm delete-btn"><i class="bi bi-trash"></i></button>
              `;
            },
          },
        ],
        language: {
          url: 'https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
        },
        autoWidth: false,
        paging: true,
        pageLength: 10,
        lengthMenu: [5, 10, 25, 50],
        order: [[0, 'desc']], // Orden descendente basado en la columna 'ID'
      });
  
      $(tableElement).on('click', '.edit-btn', function () {
        const rowData = dataTableInstance.current.row($(this).parents('tr')).data();
        handleShowModal(rowData);
      });
  
      $(tableElement).on('click', '.delete-btn', function () {
        const rowData = dataTableInstance.current.row($(this).parents('tr')).data();
        handleDeleteMesa(rowData.id);
      });
    }
  };
  

  const handleShowModal = (mesa = null) => {
    setEditingMesa(mesa);
    if (mesa) {
      console.log("Editando mesa:", mesa); // Log de la mesa que se va a editar
      setFormData({
        sucursal_id: mesa.sucursal_id ? String(mesa.sucursal_id) : '', 
        numero_mesa: mesa.numero_mesa,
      });
    } else {
      console.log("Creando nueva mesa"); // Log de nueva creación
      setFormData({
        sucursal_id: '',
        numero_mesa: '',
      });
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingMesa(null);
  };

  const handleSaveMesa = async () => {
    try {
      const sucursalId = parseInt(formData.sucursal_id, 10);
  
      console.log("Datos de formData antes de guardar:", formData);
      console.log("Sucursal ID después de conversión a entero:", sucursalId);
  
      if (!sucursalId || !formData.numero_mesa) {
        Swal.fire('Error', 'Todos los campos son obligatorios.', 'error');
        return;
      }
  
      // Verificación de cambios para evitar enviar datos sin modificaciones
      if (
        editingMesa &&
        formData.numero_mesa === editingMesa.numero_mesa &&
        sucursalId === editingMesa.sucursal_id
      ) {
        Swal.fire('Sin cambios', 'No se han realizado cambios en la mesa.', 'info');
        return;
      }
  
      const payload = {
        numero_mesa: formData.numero_mesa,
        sucursal_id: sucursalId,
      };
  
      console.log("Payload enviado a la API:", payload);
  
      let response;
      if (editingMesa) {
        response = await API.put(`/mesas?id=${editingMesa.id}`, payload);
        console.log("Respuesta de la API al actualizar:", response);
        if (response.status === 200) {
          Swal.fire('Actualizado!', 'La mesa ha sido actualizada.', 'success');
          fetchMesas();
        } else {
          Swal.fire('Error', 'No se pudo actualizar la mesa.', 'error');
        }
      } else {
        response = await API.post('/mesas', payload);
        console.log("Respuesta de la API al crear:", response);
        if (response.status === 201) {
          Swal.fire('Creado!', 'La mesa ha sido creada.', 'success');
          fetchMesas();
        } else {
          Swal.fire('Error', 'No se pudo crear la mesa.', 'error');
        }
      }
      handleCloseModal();
    } catch (error) {
      console.error('Error saving mesa:', error);
      Swal.fire('Error', 'Hubo un problema al guardar la mesa.', 'error');
    }
  };
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Cambiando ${name} a:`, value); // Log de los cambios en el formulario
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDeleteMesa = async (id) => {
    if (!id) {
      Swal.fire('Error', 'No se pudo encontrar la mesa que deseas eliminar.', 'error');
      return;
    }

    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Quieres eliminar esta mesa? Esta acción no se puede deshacer.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await API.delete(`/mesas?id=${id}`);
        Swal.fire('Eliminado!', 'La mesa ha sido eliminada.', 'success');
        fetchMesas();
      } catch (error) {
        console.error('Error deleting mesa:', error);
        Swal.fire('Error', 'Hubo un problema al eliminar la mesa.', 'error');
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Button
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        onClick={() => handleShowModal()}
      >
        Agregar Mesa
      </Button>
      <div className="card shadow-sm">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>Lista de Mesas</span>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table ref={tableRef} id="mesasTable" className="table table-striped table-bordered table-hover table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Sucursal</th>
                  <th>Número de Mesa</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {/* Deja que DataTables maneje la renderización del cuerpo */}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{editingMesa ? 'Editar Mesa' : 'Agregar Mesa'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="sucursal_id" className="mb-3 d-flex">
              <Form.Label className="col-4 text-right">Sucursal</Form.Label>
              <div className="col-8">
                <Form.Control
                  as="select"
                  name="sucursal_id"
                  value={formData.sucursal_id}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option value="">Seleccione una sucursal</option>
                  {sucursales.map((sucursal) => (
                    <option key={sucursal.id} value={sucursal.id}>
                      {sucursal.nombre}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>
            <Form.Group controlId="numero_mesa" className="mb-3 d-flex">
              <Form.Label className="col-4 text-right">Número de Mesa</Form.Label>
              <div className="col-8">
                <Form.Control
                  type="number"
                  name="numero_mesa"
                  value={formData.numero_mesa}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} className="bg-gray-500 text-white px-4 py-2 rounded">
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveMesa} className="bg-blue-500 text-white px-4 py-2 rounded">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MesasPage;
