import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes, Route, Navigate, useNavigate
} from "react-router-dom";
import { AuthContext, AuthProvider } from "./authContext";
import Sidebar from "./components/Sidebar";
import LoginForm from "./components/LoginForm";
import CheckInForm from "./components/CheckInForm";
import AdminPage from "./components/AdminPage";
import MesasPage from "./components/MesasPage";
import CategoriasPage from "./components/CategoriasPage";
import PlatillosPage from "./components/PlatillosPage";
import Swal from "sweetalert2";
import "./App.css";
import UsuariosPage from './components/UsuariosPage';

// Rutas privadas, verifica si el usuario está autenticado
const PrivateRoute = ({ children, allowedRoles }) => {
  const { isAuthenticated, user } = useContext(AuthContext);

  if (!isAuthenticated) {
    Swal.fire({
      icon: "warning",
      title: "Acceso denegado",
      text: "Debe iniciar sesión para acceder a esta página",
    });
    return <Navigate to="/login" replace />;
  }

  if (!allowedRoles.includes(user.rol)) {
    return <Navigate to="/checkin" replace />;
  }

  return children;
};

const App = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Cargando...</div>; // Muestra un indicador de carga mientras se verifica el token
  }

  return (
    <Router>
      <div className="app-container">
        {isAuthenticated && <Sidebar />}
        <div className="main-content">
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route
              path="/checkin"
              element={
                <PrivateRoute allowedRoles={['recepcion', 'admin']}>
                  <CheckInForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute allowedRoles={['comandero', 'admin']}>
                  <AdminPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/mesas"
              element={
                <PrivateRoute allowedRoles={['admin', 'comandero', 'recepcion']}>
                  <MesasPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/comidas"
              element={
                <PrivateRoute allowedRoles={['admin', 'comandero']}>
                  <PlatillosPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/categorias"
              element={
                <PrivateRoute allowedRoles={['admin']}>
                  <CategoriasPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/usuarios"
              element={
                <PrivateRoute allowedRoles={['admin']}>
                  <UsuariosPage />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/checkin" replace />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);
