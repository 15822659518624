import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import API from '../axiosConfig';
import Swal from 'sweetalert2';
import { Button, Modal, Form } from 'react-bootstrap';

const CategoriasPage = () => {
  const [categorias, setCategorias] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingCategoria, setEditingCategoria] = useState(null);
  const [formData, setFormData] = useState({ nombre: '' });

  const tableRef = useRef(null);
  const dataTableInstance = useRef(null);

  useEffect(() => {
    fetchCategorias();
  }, []);

  const fetchCategorias = async () => {
    try {
      const response = await API.get('/categorias');
      if (dataTableInstance.current) {
        dataTableInstance.current.clear().rows.add(response.data).draw();
      } else {
        initializeDataTable(response.data);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const initializeDataTable = (data) => {
    const tableElement = tableRef.current;

    if (!$.fn.dataTable.isDataTable(tableElement)) {
      dataTableInstance.current = $(tableElement).DataTable({
        data: data,
        columns: [
          { data: 'id', title: 'ID' },
          { data: 'nombre', title: 'Nombre de Categoría' },
          {
            data: null,
            title: 'Acciones',
            render: function (data, type, row) {
              return `
                <button class="btn btn-warning btn-sm edit-btn"><i class="bi bi-pencil"></i></button>
                <button class="btn btn-danger btn-sm delete-btn"><i class="bi bi-trash"></i></button>
              `;
            },
          },
        ],
        language: {
          url: 'https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
        },
        autoWidth: false,
        paging: true,
        pageLength: 10,
        lengthMenu: [5, 10, 25, 50],
      });

      $(tableElement).on('click', '.edit-btn', function () {
        const rowData = dataTableInstance.current.row($(this).parents('tr')).data();
        handleShowModal(rowData);
      });

      $(tableElement).on('click', '.delete-btn', function () {
        const rowData = dataTableInstance.current.row($(this).parents('tr')).data();
        handleDeleteCategoria(rowData.id);
      });
    }
  };

  const handleShowModal = (categoria = null) => {
    setEditingCategoria(categoria);
    if (categoria) {
      setFormData({ nombre: categoria.nombre });
    } else {
      setFormData({ nombre: '' });
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingCategoria(null);
  };

  const handleSaveCategoria = async () => {
    try {
      if (!formData.nombre.trim()) {
        Swal.fire('Error', 'El nombre de la categoría es obligatorio.', 'error');
        return;
      }

      if (editingCategoria) {
        await API.put(`/categorias?id=${editingCategoria.id}`, { nombre: formData.nombre });
        Swal.fire('Actualizado', 'La categoría ha sido actualizada.', 'success');
      } else {
        await API.post('/categorias', { nombre: formData.nombre });
        Swal.fire('Creado', 'La categoría ha sido creada.', 'success');
      }
      handleCloseModal();
      fetchCategorias();
    } catch (error) {
      console.error('Error saving category:', error);
      Swal.fire('Error', 'Hubo un problema al guardar la categoría.', 'error');
    }
  };

  const handleDeleteCategoria = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Quieres eliminar esta categoría? Esta acción no se puede deshacer.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await API.delete(`/categorias?id=${id}`);
        Swal.fire('Eliminado', 'La categoría ha sido eliminada.', 'success');
        fetchCategorias();
      } catch (error) {
        console.error('Error deleting category:', error);
        Swal.fire('Error', 'Hubo un problema al eliminar la categoría.', 'error');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="container mx-auto p-4">
      <Button
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        onClick={() => handleShowModal()}
      >
        Agregar Categoría
      </Button>
      <div className="card shadow-sm">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>Lista de Categorías</span>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table ref={tableRef} id="categoriasTable" className="table table-striped table-bordered table-hover table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre de Categoría</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {/* Deja que DataTables maneje la renderización del cuerpo */}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{editingCategoria ? 'Editar Categoría' : 'Agregar Categoría'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="nombre" className="mb-3">
              <Form.Label>Nombre de Categoría</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                className="form-control"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveCategoria}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoriasPage;
