import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import API from '../axiosConfig';
import Swal from 'sweetalert2';
import { Button, Modal, Form } from 'react-bootstrap';

const PlatillosPage = () => {
  const [categorias, setCategorias] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingPlatillo, setEditingPlatillo] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    categoria: '',
  });

  const tableRef = useRef(null);
  const dataTableInstance = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCategorias();
      await fetchPlatillos();
    };
    fetchData();
  }, []);

  const fetchPlatillos = async () => {
    try {
      const response = await API.get('/platillos');
      if (dataTableInstance.current) {
        dataTableInstance.current.clear().rows.add(response.data).draw();
      } else {
        initializeDataTable(response.data);
      }
    } catch (error) {
      console.error('Error fetching platillos:', error);
    }
  };

  const fetchCategorias = async () => {
    try {
      const response = await API.get('/categorias');
      setCategorias(response.data);
    } catch (error) {
      console.error('Error fetching categorias:', error);
    }
  };

  const initializeDataTable = (data) => {
    const tableElement = tableRef.current;

    if (!$.fn.dataTable.isDataTable(tableElement)) {
      dataTableInstance.current = $(tableElement).DataTable({
        data: data,
        columns: [
          { data: 'id', title: 'ID' },
          { data: 'nombre', title: 'Nombre' },
          { data: 'categoria_nombre', title: 'Categoría' }, // Asegúrate de que la columna 'categoria_nombre' esté bien definida en la API
          {
            data: null,
            title: 'Acciones',
            render: function (data, type, row) {
              return `
                <button class="btn btn-warning btn-sm edit-btn"><i class="bi bi-pencil"></i></button>
                <button class="btn btn-danger btn-sm delete-btn"><i class="bi bi-trash"></i></button>
              `;
            },
          },
        ],
        language: {
          url: 'https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
        },
        autoWidth: false,
        paging: true,
        pageLength: 10,
        lengthMenu: [5, 10, 25, 50],
      });

      $(tableElement).on('click', '.edit-btn', function () {
        const rowData = dataTableInstance.current.row($(this).parents('tr')).data();
        handleShowModal(rowData);
      });

      $(tableElement).on('click', '.delete-btn', function () {
        const rowData = dataTableInstance.current.row($(this).parents('tr')).data();
        handleDeletePlatillo(rowData.id);
      });
    }
  };

  const handleShowModal = (platillo = null) => {
    setEditingPlatillo(platillo);
    if (platillo) {
      setFormData({
        nombre: platillo.nombre,
        categoria: platillo.categoria_id || '', // Usa el ID de la categoría directamente
      });
    } else {
      setFormData({
        nombre: '',
        categoria: '',
      });
    }
    setShowModal(true);
  };
  

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingPlatillo(null);
  };

  const handleSavePlatillo = async () => {
    try {
      if (!formData.nombre || !formData.categoria) {
        Swal.fire('Error', 'Todos los campos son obligatorios.', 'error');
        return;
      }

      const payload = {
        nombre: formData.nombre,
        categoria: formData.categoria,
      };

      let response;
      if (editingPlatillo) {
        response = await API.put(`/platillos?id=${editingPlatillo.id}`, payload);
        if (response.status === 204) {
          Swal.fire('Actualizado!', 'El platillo ha sido actualizado.', 'success');
          fetchPlatillos();
        } else {
          Swal.fire('Error', 'No se pudo actualizar el platillo.', 'error');
        }
      } else {
        response = await API.post('/platillos', payload);
        if (response.status === 201) {
          Swal.fire('Creado!', 'El platillo ha sido creado.', 'success');
          fetchPlatillos();
        } else {
          Swal.fire('Error', 'No se pudo crear el platillo.', 'error');
        }
      }
      handleCloseModal();
    } catch (error) {
      console.error('Error saving platillo:', error);
      Swal.fire('Error', 'Hubo un problema al guardar el platillo.', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeletePlatillo = async (id) => {
    if (!id) {
      Swal.fire('Error', 'No se pudo encontrar el platillo que deseas eliminar.', 'error');
      return;
    }

    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Quieres eliminar este platillo? Esta acción no se puede deshacer.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await API.delete(`/platillos?id=${id}`);
        Swal.fire('Eliminado!', 'El platillo ha sido eliminado.', 'success');
        fetchPlatillos();
      } catch (error) {
        console.error('Error deleting platillo:', error);
        Swal.fire('Error', 'Hubo un problema al eliminar el platillo.', 'error');
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Button
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        onClick={() => handleShowModal()}
      >
        Agregar Platillo
      </Button>
      <div className="card shadow-sm">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>Lista de Platillos</span>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table ref={tableRef} id="platillosTable" className="table table-striped table-bordered table-hover table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Categoría</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {/* Deja que DataTables maneje la renderización del cuerpo */}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{editingPlatillo ? 'Editar Platillo' : 'Agregar Platillo'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="nombre" className="mb-3 d-flex">
              <Form.Label className="col-4 text-right">Nombre</Form.Label>
              <div className="col-8">
                <Form.Control
                  type="text"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </Form.Group>
            <Form.Group controlId="categoria" className="mb-3 d-flex">
              <Form.Label className="col-4 text-right">Categoría</Form.Label>
              <div className="col-8">
                <Form.Control
                  as="select"
                  name="categoria"
                  value={formData.categoria}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option value="">Seleccione una categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nombre}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} className="bg-gray-500 text-white px-4 py-2 rounded">
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSavePlatillo} className="bg-blue-500 text-white px-4 py-2 rounded">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PlatillosPage;
