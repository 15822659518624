import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import API from '../axiosConfig';
import Swal from 'sweetalert2';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

const UsuariosPage = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [roles] = useState(['admin', 'comandero', 'recepcion']); // Lista de roles posibles
  const [showModal, setShowModal] = useState(false);
  const [editingUsuario, setEditingUsuario] = useState(null);
  const [formData, setFormData] = useState({
    nombre_usuario: '',
    contraseña: '',
    sucursal_id: '',
    rol: 'recepcion',  // Valor por defecto para el rol
  });

  const tableRef = useRef(null);
  const dataTableInstance = useRef(null);

  useEffect(() => {
    fetchUsuarios();
    fetchSucursales();
  }, []);

  const fetchUsuarios = async () => {
    try {
      const response = await API.get('/usuarios');
      if (dataTableInstance.current) {
        dataTableInstance.current.clear().rows.add(response.data).draw();
      } else {
        initializeDataTable(response.data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchSucursales = async () => {
    try {
      const response = await API.get('/sucursales');
      setSucursales(response.data);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const initializeDataTable = (data) => {
    const tableElement = tableRef.current;

    if (!$.fn.dataTable.isDataTable(tableElement)) {
        dataTableInstance.current = $(tableElement).DataTable({
            data: data,
            columns: [
                { data: 'id', title: 'ID' },
                { data: 'nombre_usuario', title: 'Nombre de Usuario' },
                { data: 'sucursal_nombre', title: 'Sucursal' },  // Verifica que este campo esté recibiendo y mostrando correctamente el nombre de la sucursal
                { data: 'rol', title: 'Rol' },
                {
                    data: null,
                    title: 'Acciones',
                    render: function (data, type, row) {
                        return `
                            <button class="btn btn-warning btn-sm edit-btn"><i class="bi bi-pencil"></i></button>
                            <button class="btn btn-danger btn-sm delete-btn"><i class="bi bi-trash"></i></button>
                        `;
                    },
                },
            ],
            language: {
                url: 'https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json',
            },
            autoWidth: false,
            paging: true,
            pageLength: 10,
            lengthMenu: [5, 10, 25, 50],
        });

        $(tableElement).on('click', '.edit-btn', function () {
            const rowData = dataTableInstance.current.row($(this).parents('tr')).data();
            handleShowModal(rowData);
        });

        $(tableElement).on('click', '.delete-btn', function () {
            const rowData = dataTableInstance.current.row($(this).parents('tr')).data();
            handleDeleteUsuario(rowData.id);
        });
    }
};


  const handleShowModal = (usuario = null) => {
    setEditingUsuario(usuario);
    if (usuario) {
      setFormData({
        nombre_usuario: usuario.nombre_usuario,
        contraseña: '',
        sucursal_id: usuario.sucursal_id,
        rol: usuario.rol,  // Cargar el rol actual del usuario
      });
    } else {
      setFormData({
        nombre_usuario: '',
        contraseña: '',
        sucursal_id: '',
        rol: 'recepcion',  // Valor por defecto para el rol
      });
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingUsuario(null);
  };
  const handleSaveUsuario = async () => {
    try {
        if (!formData.nombre_usuario.trim() || !formData.sucursal_id) {
            Swal.fire('Error', 'El nombre de usuario, la sucursal y el rol son obligatorios.', 'error');
            return;
        }

        const payload = {
            nombre_usuario: formData.nombre_usuario,
            contraseña: formData.contraseña,
            sucursal_id: parseInt(formData.sucursal_id, 10),  // Asegúrate de que este valor se está enviando correctamente
            rol: formData.rol,
        };

        let response;
        if (editingUsuario) {
            if (formData.contraseña === '') {
                delete payload.contraseña;
            }
            response = await API.put(`/usuarios?id=${editingUsuario.id}`, payload);
            if (response.status === 204) {
                Swal.fire('Actualizado', 'El usuario ha sido actualizado.', 'success');
            } else {
                Swal.fire('Error', 'No se pudo actualizar el usuario.', 'error');
            }
        } else {
            response = await API.post('/usuarios?action=register', payload);
            if (response.status === 201) {
                Swal.fire('Creado', 'El usuario ha sido creado.', 'success');
            } else {
                Swal.fire('Error', 'No se pudo crear el usuario.', 'error');
            }
        }

        fetchUsuarios();
        handleCloseModal();
    } catch (error) {
        console.error('Error saving user:', error);
        Swal.fire('Error', 'Hubo un problema al guardar el usuario.', 'error');
    }
};


  const handleDeleteUsuario = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Quieres eliminar este usuario? Esta acción no se puede deshacer.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await API.delete(`/usuarios?id=${id}`);
        Swal.fire('Eliminado', 'El usuario ha sido eliminado.', 'success');
        fetchUsuarios();
      } catch (error) {
        console.error('Error deleting user:', error);
        Swal.fire('Error', 'Hubo un problema al eliminar el usuario.', 'error');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="container mx-auto p-4">
      <Button className="bg-blue-500 text-white px-4 py-2 rounded mb-4" onClick={() => handleShowModal()}>
        Agregar Usuario
      </Button>
      <div className="card shadow-sm">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>Lista de Usuarios</span>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table ref={tableRef} id="usuariosTable" className="table table-striped table-bordered table-hover table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre de Usuario</th>
                  <th>Sucursal</th>
                  <th>Rol</th> {/* Nueva columna para rol */}
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {/* Deja que DataTables maneje la renderización del cuerpo */}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{editingUsuario ? 'Editar Usuario' : 'Agregar Usuario'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="nombre_usuario" className="mb-3">
                  <Form.Label>Nombre de Usuario</Form.Label>
                  <Form.Control
                    type="text"
                    name="nombre_usuario"
                    value={formData.nombre_usuario}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="contraseña" className="mb-3">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    name="contraseña"
                    value={formData.contraseña}
                    onChange={handleChange}
                    placeholder={editingUsuario ? 'Dejar en blanco para no cambiar' : ''}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="sucursal_id" className="mb-3">
                  <Form.Label>Sucursal</Form.Label>
                  <Form.Control
                    as="select"
                    name="sucursal_id"
                    value={formData.sucursal_id}
                    onChange={handleChange}
                  >
                    <option value="">Seleccione una sucursal</option>
                    {sucursales.map((sucursal) => (
                      <option key={sucursal.id} value={sucursal.id}>
                        {sucursal.nombre}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="rol" className="mb-3">
                  <Form.Label>Rol</Form.Label>
                  <Form.Control
                    as="select"
                    name="rol"
                    value={formData.rol}
                    onChange={handleChange}
                  >
                    {roles.map((rol, index) => (
                      <option key={index} value={rol}>
                        {rol}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveUsuario}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsuariosPage;
